import React, { useState } from "react";

import logo from "./assets/images/candr_logo.png";
import "./App.scss";

function App() {

  const [result, setResult] = useState("");

  const currentYear = () => {
    const d = new Date();
    return d.getFullYear();
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setResult("Sending...")
    const formData = new FormData(e.target);

    formData.append("access_key", "4353dfe9-da01-4ea8-8fe3-6f67fdbe2ea3")

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form submitted successfully");
      e.target.reset();
    } else {
      setResult("Form submission failed - please try again later");
    }
  }


  return (
    <div className="wrapper">
      <header className="header border-gradient border-gradient__only-bottom">
        <div className="header__logo">
          <img src={logo} alt="logo" />
        </div>
      </header>
      
      <main className="main-copy">
        <h1 className="main-copy--heading">Build Your Dream Website with Clicks and Rivets</h1>
        
        <section>
          <h2 className="main-copy--heading">Create a Stunning Online Presence Today</h2>
          <p className="main-copy--text">
            Are you ready to transform your ideas into a captivating website that stands out from the crowd? 
            At Clicks and Rivets, we specialize in crafting beautiful, functional, and user-friendly websites that drive success.
          </p>
        </section>

        <section>
          <h2 className="main-copy--heading">Why Choose Clicks and Rivets?</h2>
          <ul className="main-copy--list">
            <li><strong>Custom Designs:&nbsp;</strong> 
              We believe in unique solutions tailored to your brand. Our designers create
              visually stunning websites that reflect your business' identity.
            </li>
            <li><strong>Responsive Layouts:&nbsp;</strong> 
              Every site we build is optimized for all devices, ensuring a seamless 
              experience whether your visitors are on a desktop, tablet, or smartphone.
            </li>
            <li><strong>SEO Optimized:&nbsp;</strong> 
              Our websites are built with SEO best practices in mind, helping you rank higher in 
              search engine results and attract more organic traffic.
            </li>
            <li><strong>User Friendly:&nbsp;</strong>
              We prioritize intuitive navigation and engaging interfaces, making it easy for your 
              visitors to find what they need and take action.
            </li>
            <li><strong>Secure and Reliable:&nbsp;</strong>
              With top-notch security measures and reliable hosting, you can trust that your 
              website will be safe and perform flawlessly at all times.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="main-copy--heading">Our Services Include:</h2>
          <ul className="main-copy--list">
            <li><strong>Website Design and Development:&nbsp;</strong>
              From concept to launch, we'll handle all aspectes of website creation.
            </li>
            <li><strong>E-commerce Solutions:&nbsp;</strong>
              Set up an online store that is easy to manage and converts visitors into customers.
            </li>
            <li><strong>Content Management Systems:&nbsp;</strong>
              Easy to use CMS platforms, like WordPress, give you control over your content.
            </li>
            <li><strong>Maintenance and Support:&nbsp;</strong>
              Ongoing support to keep your website updated, secure, and running smoothly.
            </li>
            <li><strong>Branding and Graphics:&nbsp;</strong>
              Enhance your website with professional branding and graphic design.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="main-copy--heading">Get Started Today</h2>
          <p className="main-copy--text">
            Don’t settle for an ordinary website. Let us create an extraordinary online presence for your business. 
            Contact us today for a free consultation and see how we can bring your vision to life.
          </p>
        </section>

        <section id="contact" class="contact-form">
        <h2 class="contact__heading">Contact Us</h2>
          <form name="contact" onSubmit={handleSubmit}>
            <div class="form-group mt-4">
              <label for="name" class="form-label">Name</label>
              <input
                id="name"
                type="text"
                name="name"
                class="form-control"
                required
              />
            </div>
            <div class="form-group mt-4">
              <label for="email" class="form-label">Email</label>
              <input
                id="email"
                type="email"
                name="email"
                class="form-control"
                required
              />
            </div>
            <div class="form-group mt-4">
              <label for="message" class="form-label">Message</label>
              <textarea
                id="message"
                name="message"
                class="form-control"
                rows="6"
                cols="50"
                required
              ></textarea>
            </div>

            <div class="row">
              <button class="btn btn-submit">Send</button>
            </div>
          </form>
          <span>{result}</span>
        </section>
      </main>

      <footer className="footer">
        <p className="footer__attribution">
          Building Websites That Build Your Business<br />
          &copy; 2019 - { currentYear() } Clicks and Rivets
        </p>
      </footer>
    </div>
  );
}

export default App;
